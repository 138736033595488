import { ACTIVATE_DRAWER, DE_ACTIVATE_DRAWER, TOGGLE_DRAWER } from "../Actions";

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER,
});

export const activateDrawer = () => ({
  type: ACTIVATE_DRAWER,
});
export const deActivateDrawer = () => ({
  type: DE_ACTIVATE_DRAWER,
});
