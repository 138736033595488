import {
  GET_DISCOUNTS,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_SPECIFIC_DISCOUNTS,
  SET_PREV_DISCOUNT_DATA,
  UPDATE_DISCOUNT_SEARCH_CATEGORY,
  UPDATE_DISCOUNT_SEARCH_VALUE,
} from "../../Actions";

import {
  createRelationIterator,
  getRelationData,
} from "../../helpers/middleware";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  currentSearchCategory: "",
  currentSearchValue: "",
  prevData: {},
};

const discountsView = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_DISCOUNTS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;
      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
      };
    }

    case `${CREATE_DISCOUNT}_SUCCESS`: {
      const { data, included } = payload;
      if (!data) {
        return store;
      }

      const newData = { ...store.data };
      if (!newData.getRelationData) {
        Object.assign(newData, { getRelationData });
      }
      newData[data.id] = { ...data };
      const newIds = [...store.dataIds, data.id];

      const newRelation = { ...store.relation };

      if (Array.isArray(included)) {
        included.forEach((item) => {
          newRelation[`${item.id}_${item.type}`] = {
            ...item,
            [Symbol.iterator]: createRelationIterator(),
          };
        });
      }

      return {
        ...store,
        data: newData,
        dataIds: newIds,
        relation: newRelation,
      };
    }

    case `${UPDATE_DISCOUNT}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      return {
        ...store,
        data: newData,
      };
    }

    case `${GET_SPECIFIC_DISCOUNTS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = { ...store.prevData };
        prevData.prevData = {};
      }

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        prevData,
      };
    }

    case SET_PREV_DISCOUNT_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
      };
    }

    case UPDATE_DISCOUNT_SEARCH_CATEGORY: {
      const { category } = payload;
      return {
        ...store,
        currentSearchCategory: category,
      };
    }

    case UPDATE_DISCOUNT_SEARCH_VALUE: {
      const { searchValue } = payload;
      return {
        ...store,
        currentSearchValue: searchValue,
      };
    }
    default:
      return store;
  }
};

export default discountsView;
