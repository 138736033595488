import {
  LOGIN_USER,
  LOGOUT_USER,
  SEND_RESET_PASSWORD_OTP,
  CLEAR_STORE,
  UPDATE_CURRENT_ISB_VERSION,
} from "../Actions";

import { loginUserApi, sendOtp, updatePassword } from "../Api/auth";

export const loginUser = ({ email, password }) => (dispatch, start) => {
  start(LOGIN_USER);
  return loginUserApi(email, password);
};

export const logoutUser = () => ({ type: LOGOUT_USER });
export const updateIsbVersion = ({ versionId }) => ({
  type: UPDATE_CURRENT_ISB_VERSION,
  payload: { versionId },
});

export const clearStore = () => ({ type: CLEAR_STORE });

export const sendOtpToResetPassword = ({ email }) => (dispatch, start) => {
  start(SEND_RESET_PASSWORD_OTP);
  return sendOtp(email);
};

export const updateUserPassword = ({
  email,
  otp,
  password,
  confirmPassword,
}) => (dispatch, start) => {
  start(SEND_RESET_PASSWORD_OTP);
  return updatePassword(email, otp, password, confirmPassword);
};
