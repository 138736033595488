import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Icon from "../Icon";
import { deActivateAlert } from "../../ActionCreator/alert";

import { getAlerts } from "../../Selectors/alert";

import PropTypes from "prop-types";

import styles from "./Alert.module.css";

const Alert = ({ alertsList, deActivateAlert }) => {
  return alertsList.map(({ position, type, message, id }, index) => {
    let posStyle = "";
    let typeStyle = "";
    const distanceStyle = {};

    switch (position) {
      case "top":
        posStyle = styles.top;
        distanceStyle.top = `${index * 60 + 15 * (index + 1)}px`;
        break;
      case "bottom":
        posStyle = styles.bottom;
        distanceStyle.bottom = `${index * 60 + 15 * (index + 1)}px`;
        break;
      case "right":
        posStyle = styles.right;
        distanceStyle.top = `${index * 60 + 15 * (index + 1)}px`;
        break;
      case "left":
        posStyle = styles.left;
        distanceStyle.top = `${index * 60 + 15 * (index + 1)}px`;
        break;
      default:
        break;
    }

    switch (type) {
      case "success":
        typeStyle = styles.success;
        break;
      case "error":
        typeStyle = styles.error;
        break;
      case "warning":
        typeStyle = styles.warning;
        break;
      case "info":
        typeStyle = styles.info;
        break;
      default:
        break;
    }

    return (
      <div
        key={id}
        style={distanceStyle}
        className={`${styles.container} ${posStyle} ${typeStyle} rc`}>
        <button onClick={() => deActivateAlert(id)} className={styles.close}>
          <Icon name="fas fa-times" />
        </button>
        <span>{String(message)}</span>
      </div>
    );
  });
};

Alert.propTypes = {
  alertsList: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["success", "error", "warning", "info"]).isRequired,
      position: PropTypes.oneOf(["top", "left", "right", "bottom"]).isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  deActivateAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (store, props) => {
  return {
    alertsList: getAlerts(store, props)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deActivateAlert
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
