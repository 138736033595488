import {
  GET_LEADERBOARD_SCORES,
  UPDATE_SELECTED_SCORE_ID,
  UPDATE_LEADERBOARD_SCORE,
  CREATE_LEADERBOARD_SCORE,
  CHANGE_LEADERBOARD_ACTION,
  SET_PREV_LEADERBOARD_DATA,
  SEARCH_PARTICIPANT_SCORE,
  GET_SORTED_LEADERBOARD_SCORE,
} from "../Actions";

const initialState = {
  data: {},
  dataIds: [],
  relation: {},
  onceFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },

  isSortByData: false,
  prevData: {},
  selectedScoreId: "",
  currentAction: "sortBy",
  // //  this fileds maintain that  all the values being added at the top if the value is being from CREATE_REACT_SCORE
  // lastInsertedRowPosition: -1,
};

const leaderBoard = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_LEADERBOARD_SCORES}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
        isSortByData: false,
      };
    }

    case `${CREATE_LEADERBOARD_SCORE}_SUCCESS`: {
      const { data, relation } = payload;

      if (store.currentAction) {
        return store;
      }

      // if (Object.keys(store.data).length > 0) {
      //   const newData = { ...store.data };
      //   Object.keys(data).forEach((key) => {
      //     newData[data[key].id] = {
      //       ...data[key],
      //       attributes: {
      //         cs: data[key].attributes.scores.cs,
      //         ml: data[key].attributes.scores.ml,
      //         dbms: data[key].attributes.scores.dbms,
      //         react: data[key].attributes.scores.react,
      //         total: data[key].attributes.scores.total,
      //       },
      //       reportLinks: {
      //         ...(data[key].attributes.reportLinks || {}),
      //       },
      //       originalPosition: store.lastInsertedRowPosition,
      //     };
      //   });

      //const newRelation = { ...store.relation, ...relation };

      return {
        ...store,
        // data: newData,
        // relation: newRelation,
        // lastInsertedRowPosition: store.lastInsertedRowPosition - 1,
      };
    }

    case `${SEARCH_PARTICIPANT_SCORE}_SUCCESS`:
    case `${GET_SORTED_LEADERBOARD_SCORE}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
        Reflect.deleteProperty(prevData, "prevData");
        Reflect.deleteProperty(prevData, "selectedScoreId");
        Reflect.deleteProperty(prevData, "currentAction");
      } else {
        prevData = store.prevData;
      }

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        prevData,
        onceFetched: true,
        isSortByData: type === `${GET_SORTED_LEADERBOARD_SCORE}_SUCCESS`,
      };
    }

    case `${UPDATE_LEADERBOARD_SCORE}_SUCCESS`: {
      const { data } = payload;

      const newData = { ...store.data };
      newData[data.id] = { ...data };

      return {
        ...store,
        data: newData,
      };
    }

    case SET_PREV_LEADERBOARD_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
      };
    }

    case CHANGE_LEADERBOARD_ACTION: {
      const { action } = payload;
      return {
        ...store,
        currentAction: action,
      };
    }
    case UPDATE_SELECTED_SCORE_ID: {
      const { id } = payload;
      return {
        ...store,
        selectedScoreId: id,
      };
    }

    default:
      return store;
  }
};

export default leaderBoard;
