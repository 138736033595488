import { useCallback, useEffect, useState } from "react";

const checkView = (minWidth, maxWidth) => {
  return () => {
    if (window.innerWidth >= minWidth && window.innerWidth <= maxWidth) {
      return true;
    }
    return false;
  };
};

const mobileViewCheck = checkView(0, 425);
const tabViewCheck = checkView(426, 1023);
const descktopViewCheck = checkView(1024, 3000);

const useDimensions = () => {
  const [viewStatus, updateStatus] = useState({
    isMobileView: false,
    isTabView: false,
    isdesktopView: false,
  });

  const onResize = useCallback(() => {
    updateStatus({
      isMobileView: mobileViewCheck(),
      isTabView: tabViewCheck(),
      isdesktopView: descktopViewCheck(),
    });
  }, []);

  useEffect(() => {
    updateStatus({
      isMobileView: mobileViewCheck(),
      isTabView: tabViewCheck(),
      isdesktopView: descktopViewCheck(),
    });

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return {
    viewStatus,
  };
};

export default useDimensions;
