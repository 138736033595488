import { GET_ISB_REGISTRATION_BY_DATE } from "../Actions";

const initialStore = {
  data: [],
  fromDate: null,
  toDate: null,
};

const dailyReports = (store = initialStore, action) => {
  const { type, payload, fromDate, toDate } = action;
  switch (type) {
    case `${GET_ISB_REGISTRATION_BY_DATE}_SUCCESS`: {
      const result = payload.data;
      return {
        ...store,
        data: {
          ...payload.data,
        },
        fromDate: fromDate,
        toDate: toDate,
      };
    }

    default:
      return store;
  }
};

export default dailyReports;
