import {
  GET_TOP_RANK_HOLDERS,
  UPDATE_CERTIFICATE_META_DATA,
} from "../../Actions";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},

  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },

  rankRange: {
    from: 1,
    to: 1,
  },
  subjectOfCertificate: "",
};

const createCertificate = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_TOP_RANK_HOLDERS}_SUCCESS`: {
      const {
        data,
        relation,
        dataIds,

        paginationData,
      } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,

        paginationData,
        onceFetched: true,
      };
    }

    case UPDATE_CERTIFICATE_META_DATA: {
      const { fromRank, toRank, subject } = payload;
      return {
        ...store,
        rankRange: {
          from: Number(fromRank),
          to: Number(toRank),
        },
        subjectOfCertificate: subject,
      };
    }

    case "CLEAR_CREATE_CERTIFICATE_DATA": {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],
        onceFetched: false,

        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
      };
    }

    default:
      return store;
  }
};

export default createCertificate;
