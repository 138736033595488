import { useSelector } from "react-redux";

import { isUserLoggedIn, getAccessType } from "../../Selectors/auth";

import PropTypes from "prop-types";

const OnlyWhenAuthenticated = ({ children }) => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const hasAccessType = useSelector(getAccessType);

  return isLoggedIn && hasAccessType ? children : null;
};

OnlyWhenAuthenticated.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OnlyWhenAuthenticated;
