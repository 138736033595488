import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDimensions from "./useDimensions";

import {
  toggleDrawer as toggleDrawerAC,
  activateDrawer as activateDrawerAC,
  deActivateDrawer as deActivateDrawerAC,
} from "../ActionCreator/appData";

import { getDrawerStatus } from "../Selectors/appData";
const useDrawer = () => {
  const { viewStatus } = useDimensions();
  const dispatch = useDispatch();
  const drawerStatus = useSelector(getDrawerStatus);

  const toggleDrawer = useCallback(
    (status = "", isForced = false) => {
      if (viewStatus.isdesktopView && !isForced) {
        return;
      }

      if (typeof status === "boolean") {
        if (status) {
          dispatch(activateDrawerAC());
        } else {
          dispatch(deActivateDrawerAC());
        }
      } else {
        dispatch(toggleDrawerAC());
      }
    },
    [toggleDrawerAC, activateDrawerAC, deActivateDrawerAC, dispatch, viewStatus]
  );

  const activateDrawer = useCallback(
    (isForced = false) => {
      toggleDrawer(true, isForced);
    },
    [toggleDrawer, viewStatus]
  );

  const deActivateDrawer = useCallback(
    (isForced = false) => {
      toggleDrawer(false, isForced);
    },
    [toggleDrawer, viewStatus]
  );

  useEffect(() => {
    if (viewStatus.isdesktopView) {
      activateDrawer(true);
    }

    if (viewStatus.isMobileView || viewStatus.isTabView) {
      deActivateDrawer(true);
    }
  }, [viewStatus]);

  return {
    drawerActive: drawerStatus,
    activateDrawer,
    deActivateDrawer,
    toggleDrawer,
  };
};

export default useDrawer;
