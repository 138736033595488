import {
  GET_DATABASE_RECORDS_COUNT,
  GET_DATABASE_RECORDS,
  UPDATE_DATABASE_SEARCH_CATEGORY,
  SET_PREV_DATABASE_DATA,
  GET_SPECIFIC_DATABASE_RECORDS,
  UPDATE_DATABASE_SEARCH_VALUE,
} from "../Actions";

const initialState = {
  totalRegistrations: null,
  dataIds: [],
  data: {},
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  currentSearchCategory: "",
  currentSearchValue: "",
  prevData: {},
};

const database = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_DATABASE_RECORDS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
      };
    }

    case `${GET_SPECIFIC_DATABASE_RECORDS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;
      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = { ...store.prevData };
        prevData.prevData = {};
      }

      Reflect.deleteProperty(prevData, "currentSearchCategory");
      Reflect.deleteProperty(prevData, "currentSearchValue");

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
        prevData,
      };
    }

    case `${GET_DATABASE_RECORDS_COUNT}_SUCCESS`: {
      const { count } = payload;
      return {
        ...store,
        totalRegistrations: count,
      };
    }

    case SET_PREV_DATABASE_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
      };
    }

    case UPDATE_DATABASE_SEARCH_CATEGORY: {
      const { category } = payload;
      return {
        ...store,
        currentSearchCategory: category,
      };
    }

    case UPDATE_DATABASE_SEARCH_VALUE: {
      const { searchValue } = payload;
      return {
        ...store,
        currentSearchValue: searchValue,
      };
    }
    default:
      return store;
  }
};

export default database;
