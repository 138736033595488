import { ACTIVATE_ALERT, DE_ACTIVATE_ALERT } from "../Actions";
import { generateRandomId } from "../helpers";

export const deActivateAlert = (id) => {
  return {
    type: DE_ACTIVATE_ALERT,
    payload: { id }
  };
};

export const activateAlert = ({
  message,
  type = "success",
  position = "top",
  fixed = false,
  maxTime = 3000
}) => (dispatch) => {
  const id = generateRandomId();
  dispatch({
    type: ACTIVATE_ALERT,
    payload: { message, type, position, id }
  });

  if (!fixed) {
    setTimeout(() => {
      dispatch(deActivateAlert(id));
    }, maxTime);
  }
};
