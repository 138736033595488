export const mailRoutes = {
  mailManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      createMail: {
        path: "/create_mail",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Mail",
        drawerIcon: "fas fa-pencil-alt",
      },
      editMail: {
        path: "/edit_mail",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Edit Mail",
        drawerIcon: "fas fa-wrench",
      },

      sendMail: {
        path: "/send_mail",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "Send Mail",
        drawerIcon: "fas fa-paper-plane",
      },

      sendWelcomeNotification: {
        path: "/send_welcome_notification",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "Welcome Notifications",
        drawerIcon: "fas fa-handshake",
      },

      registerMail: {
        path: "/register_mail",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Registration Mail",
        drawerIcon: "fas fa-mail-bulk",
      },

      emailReports: {
        path: "/email_reports",
        accessType: ["super_admin"],
        drawerTitle: "Email Reports",
        drawerIcon: "fas fa-file-signature",
      },

      unsubscribeMail: {
        path: "/unsubscribe_mail",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Unsubscribe Mail",
        drawerIcon: "fas fa-user-slash",
      },
    },
  },
};
