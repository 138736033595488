import {
  CHANGE_ANNOUNCEMENT_CATEGORY,
  GET_ISB_ANNOUNCEMENT,
  UPDATE_ISB_ANNOUNCEMENT,
  DELETE_ISB_ANNOUNCEMENT,
  CREATE_ISB_ANNOUNCEMENT,
} from "../Actions";

const initialStore = {
  currentCategoryId: "",
  dataFetched: false,
  data: {},
  dataIds: [],
  relation: {},

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const announcement = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_ISB_ANNOUNCEMENT}_SUCCESS`: {
      const { data, dataIds, relation, paginationData } = payload;
      return {
        ...store,
        data,
        paginationData,
        dataIds,
        relation,
        dataFetched: true,
      };
    }

    case `${CREATE_ISB_ANNOUNCEMENT}_SUCCESS`:
    case `${UPDATE_ISB_ANNOUNCEMENT}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      const newIds = [...store.dataIds, data.id];
      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    case `${DELETE_ISB_ANNOUNCEMENT}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      Reflect.deleteProperty(newData, data.id);
      const newIds = [...store.dataIds.filter((item) => item !== data.id)];
      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    case CHANGE_ANNOUNCEMENT_CATEGORY: {
      const { id } = payload;
      return {
        ...store,
        currentCategoryId: id,
      };
    }

    default:
      return store;
  }
};

export default announcement;
