import React, { lazy } from "react";
import Routes from "../../Constants/Routes";
import AdvancedRoute from "../../Components/AdvancedRoute";

const discountManagement = Routes.authorized.discountManagement.routes;

const CreateDiscountPage = lazy(() =>
  import("../../Pages/Discounts/CreateDiscount")
);
const ViewDiscountsPage = lazy(() =>
  import("../../Pages/Discounts/ViewDiscounts")
);

export const DiscountRoutes = () => {
  return (
    <>
      <AdvancedRoute
        exact
        path={discountManagement.createDiscount.path}
        accessType={discountManagement.createDiscount.accessType}
        component={CreateDiscountPage}
      />

      <AdvancedRoute
        exact
        path={discountManagement.viewDiscounts.path}
        accessType={discountManagement.viewDiscounts.accessType}
        component={ViewDiscountsPage}
      />
    </>
  );
};
