export const discountsRoutes = {
  discountManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      createDiscount: {
        path: "/create_discount",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Discount",
        drawerIcon: "fas fa-pencil-alt",
      },
      viewDiscounts: {
        path: "/view_discounts",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "View Discounts",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};
