export * from "./Certificate";
export const TOKEN_LOCAL_NAME = "AJ((@CI(*%#*&#";
export const USER_LOCAL_DATA = "c9w87yAX*&T*&GQ*wdgiYGWSD";
export const CURRENT_ISB_DATA = "JBGUT8BT48@&!@^NN*&N@N@#F";
export const UN_ANSWERED_QUERY = "un_answered";
export const ANSWERED_QUERY = "answered";
export const ALL_QUERIES = "all";
export const SEARCH_BY_TICKET = "search_by_ticketId";
export const UTM_CAMPAIGN = "utm_campaign";
export const UTM_MEDIUM = "utm_medium";
export const UTM_SOURCE = "utm_source";

export const accessTypeOptions = [
  { name: "Admin", value: "admin" },
  { name: "Super Admin", value: "super_admin" },
  { name: "Sales Executive", value: "sales_executive" },
];

export const emailRecipient = [
  {
    name: "Unregistered Participants",
    value: "unregistered_participants",
  },
];
