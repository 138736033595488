import {
  GET_REFUND_REGISTRATIONS,
  CREATE_REFUND_REGISTRATION
} from "../Actions";

const initialState = {
  totalRegistrations: null,
  data: {},
  relation: {},
  columns: [],
  onceFetched: false,
  /**
   * ==== relationAttributes====
   * contains all the attributes of their respective category
   * using map to maintain insertion order
   */

  relationAttributes: new Map(),
  excludedRelation: { isb: true },
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  }
};

const refundRegistration = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_REFUND_REGISTRATIONS}_SUCCESS`: {
      const {
        data,
        relation,
        columns,
        relationAttributes,
        paginationData
      } = payload;

      return {
        ...store,
        data,
        relation,
        columns,
        relationAttributes,
        paginationData,
        onceFetched: true
      };
    }

    case `${CREATE_REFUND_REGISTRATION}_SUCCESS`: {
      const { data, relation } = payload;
      const newData = { ...store.data, ...data };
      const newRelation = { ...store.relation, ...relation };

      return {
        ...store,
        data: newData,
        relation: newRelation
      };
    }

    default:
      return store;
  }
};

export default refundRegistration;
