import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateIsbVersion } from "../ActionCreator/auth";
import { getIsbVersionOptions, getCurrentIsbData } from "../Selectors/auth";

const useIsbVersion = () => {
  const dispatch = useDispatch();
  const versionOptions = useSelector(getIsbVersionOptions);
  const currentIsbData = useSelector(getCurrentIsbData);

  const changeIsbVersion = useCallback((id) => {
    if (!id) {
      return;
    }
    dispatch(updateIsbVersion({ versionId: id }));
  }, []);

  return {
    versionOptions,
    changeIsbVersion,
    currentIsbData,
  };
};

export default useIsbVersion;
