import {
  GET_ISB_USER_RECORD,
  UPDATE_ISB_USER_DATA,
  GET_ALL_ISB_DATA,
  DELETE_ISB_USER_RECORD,
} from "../../Actions";

const initialState = {
  data: {},
  dataIds: [],
  allIsbVersions: [],
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const user = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ISB_USER_RECORD}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
      };
    }

    case `${GET_ALL_ISB_DATA}_SUCCESS`: {
      const { dataIds, data } = payload;
      const result = [];
      if (Array.isArray(dataIds)) {
        dataIds.forEach((id) => {
          if (!data[id]) {
            return;
          }

          const { version } = data[id].attributes;
          result.push({ id, version });
        });
      }
      return {
        ...store,
        allIsbVersions: result,
      };
    }

    case `${UPDATE_ISB_USER_DATA}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      return {
        ...store,
        data: newData,
      };
    }

    case `${DELETE_ISB_USER_RECORD}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      Reflect.deleteProperty(newData, data.id);
      const newIds = [...store.dataIds.filter((id) => id !== data.id)];

      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    default:
      return store;
  }
};

export default user;
