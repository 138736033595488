import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_CURRENT_ISB_VERSION,
} from "../Actions";
import {
  TOKEN_LOCAL_NAME,
  USER_LOCAL_DATA,
  CURRENT_ISB_DATA,
} from "../Constants";

import { setGlobalUserDetils } from "../GlobalData";
const initialState = {
  isLoggedIn: sessionStorage.getItem(TOKEN_LOCAL_NAME) ? true : false,
  token: sessionStorage.getItem(TOKEN_LOCAL_NAME) || "",
  userData: sessionStorage.getItem(USER_LOCAL_DATA)
    ? JSON.parse(sessionStorage.getItem(USER_LOCAL_DATA))
    : {
        id: "",
        name: "",
        email: "",
        isbData: {},
      },

  currentIsbData: sessionStorage.getItem(CURRENT_ISB_DATA)
    ? JSON.parse(sessionStorage.getItem(CURRENT_ISB_DATA))
    : {
        version: "",
        id: "",
      },
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${LOGIN_USER}_SUCCESS`: {
      const { data, meta, included } = payload;
      const { attributes, relationships } = data;
      const { name, email } = attributes;

      const isbData = {};
      if (
        relationships.validIsbs &&
        Array.isArray(relationships.validIsbs.data) &&
        Array.isArray(included)
      ) {
        const relationObj = {};
        included.forEach((item) => {
          const key = `${item.id}_${item.type}`;
          relationObj[key] = { ...item };
        });

        relationships.validIsbs.data.forEach((item) => {
          const key = `${item.id}_${item.type}`;

          isbData[item.id] = {
            id: item.id,
            ...((relationObj[key] && relationObj[key].attributes) || {}),
          };
        });

        relationships.validUserIsbs.data.forEach((item) => {
          const key = `${item.id}_${item.type}`;

          const { isbId, accessType } = relationObj[key].attributes;

          isbData[String(isbId)] = {
            ...(isbData[String(isbId)] || {}),
            accessType,
          };
        });
      }

      setGlobalUserDetils({
        id: data.id,
        name,
        email,
      });

      return {
        ...state,
        isLoggedIn: true,
        token: meta.token,
        userData: {
          id: data.id,
          name,
          email,
          isbData,
        },
      };
    }

    case UPDATE_CURRENT_ISB_VERSION: {
      const { versionId } = payload;
      setGlobalUserDetils({
        accessType: state.userData.isbData[versionId].accessType,
      });
      return {
        ...state,
        userData: {
          ...state.userData,
          accessType: state.userData.isbData[versionId].accessType,
        },
        currentIsbData: {
          version: state.userData.isbData[versionId].version,
          id: versionId,
        },
      };
    }

    case `${LOGOUT_USER}`: {
      return {
        ...state,
        isLoggedIn: false,

        token: "",
        userData: {
          id: "",
          name: "",
          email: "",
          isbData: {},
        },

        currentIsbData: {
          version: "",
          id: "",
        },
      };
    }
    default:
      return state;
  }
};

export default auth;
