import {
  CREATE_SUBJECT,
  CREATE_LECTURE,
  GET_SUBJECTS,
  GET_LECTURES,
  CLEAR_LECTURES,
  CHANGE_SUBJECT_TO_EDIT,
  CHANGE_LECTURE_TO_EDIT,
  CHANGE_LECTURE_MATERIAL_TO_EDIT,
  UPDATE_SUBJECT,
  UPDATE_LECTURE,
  UPDATE_LECTURE_MATERIAL,
  DELETE_SUBJECT,
  DELETE_LECTURE,
  DELETE_LECTURE_MATERIAL
} from "../Actions";

const initialStore = {
  subjects: {},
  lectures: {},
  materials: {},
  materialTypes: {},
  subjectsFetched: false,
  editSubjectId: "",
  editLectureId: "",
  editLectureMaterialId: ""
};
const studyMaterial = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_SUBJECT}_SUCCESS`: {
      const { data } = payload;
      const newSubjects = { ...store.subjects };
      newSubjects[data.id] = { ...data };
      return {
        ...store,
        subjects: newSubjects
      };
    }

    case `${CREATE_LECTURE}_SUCCESS`: {
      const { data } = payload;
      const newLectures = { ...store.lectures };
      newLectures[data.id] = { ...data };

      return {
        ...store,
        lectures: newLectures
      };
    }

    case `${GET_SUBJECTS}_SUCCESS`: {
      const { data = [] } = payload;
      const newSubjects = { ...store.subjects };
      data.forEach((item) => {
        newSubjects[item.id] = { ...item };
      });
      return {
        ...store,
        subjects: newSubjects,
        subjectsFetched: true
      };
    }

    case `${GET_LECTURES}_SUCCESS`: {
      const { data, included = [] } = payload;
      const newLectures = {};
      const newMaterials = {};
      const materialTypes = { ...(data.attributes.materialTypes || {}) };
      included.forEach((item) => {
        if (item.type === "isbLectures") {
          newLectures[item.id] = { ...item };
        } else {
          newMaterials[item.id] = { ...item };
        }
      });
      return {
        ...store,
        materialTypes,
        lectures: newLectures,
        materials: newMaterials
      };
    }

    case CLEAR_LECTURES: {
      return {
        ...store,
        lectures: {}
      };
    }

    case `${UPDATE_SUBJECT}_SUCCESS`: {
      const { data } = payload;
      const newSubjects = { ...store.subjects };
      newSubjects[data.id] = { ...data };
      return {
        ...store,
        subjects: newSubjects
      };
    }

    case `${UPDATE_LECTURE}_SUCCESS`: {
      const { data } = payload;
      const newLectures = { ...store.lectures };
      newLectures[data.id] = { ...data };
      return {
        ...store,
        lectures: newLectures
      };
    }

    case `${UPDATE_LECTURE_MATERIAL}_SUCCESS`: {
      const { data } = payload;
      const newMaterials = { ...store.materials };
      newMaterials[data.id] = { ...data };
      return {
        ...store,
        materials: newMaterials
      };
    }

    case `${DELETE_SUBJECT}_SUCCESS`: {
      const { data } = payload;
      const newSubjects = { ...store.subjects };
      Reflect.deleteProperty(newSubjects, data.id);
      return {
        ...store,
        subjects: newSubjects
      };
    }

    case `${DELETE_LECTURE}_SUCCESS`: {
      const { data } = payload;
      const newLectures = { ...store.lectures };
      Reflect.deleteProperty(newLectures, data.id);
      return {
        ...store,
        lectures: newLectures
      };
    }

    case `${DELETE_LECTURE_MATERIAL}_SUCCESS`: {
      const { data } = payload;
      const newMaterials = { ...store.materials };
      Reflect.deleteProperty(newMaterials, data.id);
      return {
        ...store,
        materials: newMaterials
      };
    }

    case CHANGE_SUBJECT_TO_EDIT: {
      const { id } = payload;
      return {
        ...store,
        editSubjectId: id
      };
    }

    case CHANGE_LECTURE_TO_EDIT: {
      const { id } = payload;
      return {
        ...store,
        editLectureId: id
      };
    }

    case CHANGE_LECTURE_MATERIAL_TO_EDIT: {
      const { id } = payload;
      return {
        ...store,
        editLectureMaterialId: id
      };
    }

    default: {
      return store;
    }
  }
};

export default studyMaterial;
