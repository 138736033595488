import React, { useMemo } from "react";

import DrawerItem from "../DrawerItem";
import DrawerTab from "../DrawerTab";
import Routes from "../../../Constants/Routes";
import { createDrawerItems } from "../../../helpers";

const { seminarManagement } = Routes.authorized;

const tabLinks = Object.keys(seminarManagement.routes).map(
  (key) => seminarManagement.routes[key].path
);

const SeminarTab = ({ location }) => {
  const shouldSeminarBeCollapsed = useMemo(() => {
    return tabLinks.includes(location.pathname);
  }, [location]);

  const items = useMemo(() => {
    return createDrawerItems(seminarManagement.routes, location, DrawerItem);
  }, [location]);

  return (
    <DrawerTab
      active={shouldSeminarBeCollapsed}
      currentPath={location.pathname}
      title="Workshop"
      icon="fas fa-users"
      accessType={seminarManagement.accessType}
    >
      {items}
    </DrawerTab>
  );
};

export default SeminarTab;
