const global_field = "isb";

window[global_field] = {};

export const setGlobalUserDetils = (details) => {
  window[global_field]["userDetails"] = {
    ...(window[global_field]["userDetails"] || {}),
    ...details,
  };
};

export const getGlobalIsbField = (field) => {
  return window[global_field][field] || null;
};
