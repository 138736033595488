export const ambassadorRoutes = {
  ambassadorManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      createCapmusAmbassador: {
        path: "/create_capmus_ambassador",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Ambassador",
        drawerIcon: "fas fa-pencil-alt",
      },
      viewCapmusAmbassador: {
        path: "/capmus_ambassador",
        accessType: ["admin", "super_admin"],
        drawerTitle: "View Ambassadors",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};
