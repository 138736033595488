import {
  GET_ANSWERED_QUERIES,
  GET_UN_ANSWERED_QUERIES,
  GET_ALL_QUERIES,
  DELETE_TABLE_RECORD,
  CHANGE_ENQUIRY_CATEGORY,
  GET_QUERIES_BY_TICKET_ID,
} from "../Actions";
import { ALL_QUERIES } from "../Constants";

const initialState = {
  totalRegistrations: null,
  data: {},
  relation: {},
  dataIds: [],
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  currentCategoySelected: ALL_QUERIES,
};

const enquiry = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ALL_QUERIES}_SUCCESS`:
    case `${GET_UN_ANSWERED_QUERIES}_SUCCESS`:
    case `${GET_ANSWERED_QUERIES}_SUCCESS`:
    case `${GET_QUERIES_BY_TICKET_ID}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
      };
    }

    case CHANGE_ENQUIRY_CATEGORY: {
      const { category } = payload;
      return {
        ...store,
        currentCategoySelected: category,
      };
    }

    case DELETE_TABLE_RECORD: {
      const { id } = payload;
      const newData = { ...store.data };
      delete newData[id];
      const newIds = [...store.dataIds.filter((itemId) => itemId !== id)];

      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    case `CLEAR_ENQUIRY_DATA`: {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],

        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
        prevTableData: {},
      };
    }
    default:
      return store;
  }
};

export default enquiry;
