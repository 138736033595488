export const studyMaterialRoutes = {
  studyMaterialManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      createSubject: {
        path: "/create_subject",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Subject",
        drawerIcon: "fas fa-pencil-alt",
      },

      editSubject: {
        path: "/edit_subject",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Edit Subject",
        drawerIcon: "fas fa-cogs",
      },

      createLecture: {
        path: "/create_lecture",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Lecture",
        drawerIcon: "fas fa-pencil-alt",
      },

      editLecture: {
        path: "/edit_lecture",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Edit Lecture",
        drawerIcon: "fas fa-cogs",
      },

      createLectureMaterial: {
        path: "/create_lecture_material",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Materials",
        drawerIcon: "fas fa-pencil-alt",
      },

      editLectureMaterial: {
        path: "/edit_lecture_material",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Edit Material",
        drawerIcon: "fas fa-cogs",
      },
    },
  },
};
