import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logoutUser, clearStore } from "../ActionCreator/auth";

const useLogout = ({ onSuccess, onFailed }) => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    if (window.confirm("Are you sure you want to logout?")) {
      dispatch(logoutUser());
      setTimeout(() => {
        dispatch(clearStore());
      }, 1000);

      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } else {
      if (typeof onFailed === "function") {
        onFailed();
      }
    }
  }, [onSuccess, onFailed]);

  return {
    logout,
  };
};

export default useLogout;
