export const certificateRoutes = {
  certificateManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      createCertificate: {
        path: "/create_certificate",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Certificate",
        drawerIcon: "fas fa-pencil-alt",
      },
      sendCertificate: {
        path: "/send_certificate",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Send Certificate",
        drawerIcon: "fas fa-paper-plane",
      },

      // viewCertificate: {
      //   path: "/view_certificate",
      //   accessType: ["admin", "super_admin"],
      //   drawerTitle: "View Certificate",
      //   drawerIcon: "fa fa-eye",
      // },

      viewCertificateByRank: {
        path: "/view_certificate_by_rank",
        accessType: ["admin", "super_admin"],
        drawerTitle: "View Certificate By Rank",
        drawerIcon: "fa fa-eye",
      },
    },
  },
};
