import React, { useRef } from "react";

import Icon from "../Icon";
import styles from "./Input.module.css";
import useInput from "./useInput";

const Select = ({
  defaultSelectText = "",
  options = [],
  multiple,
  onChange,
  ...otherprops
}) => {
  const {
    containerClass,
    inputClass,
    iconClass,
    icon,
    restInputProps,
  } = useInput(otherprops);

  const selectRef = useRef(null);

  const onSelectChange = (e) => {
    if (typeof onChange !== "function") {
      return;
    }
    let element = { target: { value: e.target.value } };

    if (multiple) {
      const options = selectRef.current.options;
      const values = [...options]
        .filter((item) => item.selected)
        .map((item) => item.value);
      element = { target: { value: values } };
    }
    onChange(element);
  };

  return (
    <div className={containerClass}>
      <select
        multiple={multiple}
        ref={selectRef}
        onChange={onSelectChange}
        {...restInputProps}
        className={inputClass}
      >
        <option className={styles.select} value="">
          {defaultSelectText}
        </option>

        {options.map((item) => {
          return (
            <option
              className={styles.select}
              key={item.value}
              value={item.value}
            >
              {item.name}
            </option>
          );
        })}
      </select>
      {!!icon && <Icon className={iconClass} name={icon} />}
    </div>
  );
};

export default Select;
