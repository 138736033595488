import {
  GET_SEARCH_BY_TYPE_DATA,
  UPDATE_SEARCH_BY_FIELDS_DATA,
} from "../Actions";

const initialState = {
  data: {},
  relation: {},
  dataIds: [],
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  searchFieldsData: {
    searchBy: "",
    searchValue: "",
  },
};

const searchBy = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_SEARCH_BY_TYPE_DATA}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
      };
    }

    case `CLEAR_SEARCH_BY_DATA`: {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],

        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
        searchFieldsData: {
          searchBy: "",
          searchValue: "",
        },
      };
    }

    case UPDATE_SEARCH_BY_FIELDS_DATA: {
      const { searchBy, searchValue } = payload;
      return {
        ...store,
        searchFieldsData: {
          searchBy,
          searchValue,
        },
      };
    }

    default:
      return store;
  }
};

export default searchBy;
