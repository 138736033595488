import React from "react";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import MainRoute from "./Routes";
import store from "./Store";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <MainRoute />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
