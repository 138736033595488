import React, { useState, useRef, useCallback, useEffect } from "react";

import styles from "./Collapse.module.css";
import Icon from "../Icon";

const Collapse = ({
  shouldBeCollapsed = false,
  icon = "",
  heaidng,
  header = null,
  className = "",
  headingClass,
  children,
}) => {
  const activeRef = useRef(false);
  const [contentStyle, updateStyle] = useState({ height: "auto", opacity: 0 });
  const initialHeight = useRef("auto");
  const maxHeight = useRef(null);
  const contentRef = useRef();
  const headerRef = useRef();
  const isHeaderSet = useRef(false);

  const setContentHeight = useCallback(() => {
    if (!contentRef.current) {
      return;
    }

    if (activeRef.current) {
      const contentHeight = contentRef.current.clientHeight;

      const newHeight =
        contentHeight === 0
          ? maxHeight.current + initialHeight.current
          : initialHeight.current + contentHeight;

      if (!maxHeight.current) {
        maxHeight.current = newHeight;
      }
      updateStyle({
        height: `${newHeight}px`,
        opacity: 1,
      });
    } else {
      updateStyle({ height: `${initialHeight.current}px`, opacity: 0 });
    }
  }, []);

  const toggleCollapse = useCallback(() => {
    activeRef.current = !activeRef.current;
    setContentHeight();
  }, [setContentHeight]);

  const setHeaderHeight = useCallback(() => {
    // if(!headerRef.current){
    //   return
    // }
    const { height } = headerRef.current.getBoundingClientRect();

    initialHeight.current = height;
    if (!activeRef.current) {
      updateStyle({ height: `${height}px`, opacity: 1 });
    } else {
      setContentHeight();
    }
  }, [setContentHeight]);

  const setHeaderRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }
      headerRef.current = ref;
      setTimeout(() => {
        setHeaderHeight();
        isHeaderSet.current = true;
      }, [10]);
    },
    [setHeaderHeight]
  );

  useEffect(() => {
    if (shouldBeCollapsed) {
      activeRef.current = true;
    } else {
      activeRef.current = false;
    }

    if (!isHeaderSet.current) {
      return;
    }

    setContentHeight();
  }, [shouldBeCollapsed]);

  return (
    <div
      style={{
        height: contentStyle.height,
      }}
      className={`${styles.container} ${className}`}
    >
      <div
        id="collapse_header"
        className={styles.header}
        ref={setHeaderRef}
        role="button"
        onClick={toggleCollapse}
      >
        {header || (
          <div className={`${styles.heaidng} ${headingClass}`}>
            {!!icon && <Icon name={icon} />}
            <span>{heaidng}</span>
          </div>
        )}

        <Icon
          className={styles.drop_icon}
          name={`fas fa-caret-${activeRef.current ? "up" : "down"}`}
        />
      </div>

      <div ref={contentRef} className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;
