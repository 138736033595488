import React, { lazy } from "react";
import Routes from "../../Constants/Routes";
import AdvancedRoute from "../../Components/AdvancedRoute";

const seminarManagement = Routes.authorized.seminarManagement.routes;

const RegisteredParticipants = lazy(() =>
  import("../../Pages/Seminar/Registered")
);
const UnRegisteredParticipants = lazy(() =>
  import("../../Pages/Seminar/Unregistered")
);

export const SeminarRoutes = () => {
  return (
    <>
      <AdvancedRoute
        exact
        path={seminarManagement.viewRegistered.path}
        accessType={seminarManagement.viewRegistered.accessType}
        component={RegisteredParticipants}
      />

      <AdvancedRoute
        exact
        path={seminarManagement.viewUnRegistered.path}
        accessType={seminarManagement.viewUnRegistered.accessType}
        component={UnRegisteredParticipants}
      />
    </>
  );
};
