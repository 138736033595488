import React from "react";
import { AnnouncementRoutes } from "./Announcement";
import { DiscountRoutes } from "./Discounts";
import { SeminarRoutes } from "./Seminar";

export const RootRoute = () => {
  return (
    <>
      <DiscountRoutes />
      <AnnouncementRoutes />
      <SeminarRoutes />
    </>
  );
};
