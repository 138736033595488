import { createSelector } from "reselect";

const storeUserData = (store, props) => store.auth.userData;
const storeToken = (store, props) => store.auth.token;
const storeLoggedIn = (store, props) => store.auth.isLoggedIn;
const storeCurrentIsbData = (store, props) => store.auth.currentIsbData;

export const getUserData = createSelector(
  storeUserData,
  (userData) => userData
);

export const getCurrentIsbData = createSelector(
  storeCurrentIsbData,
  (isbData) => isbData || { version: "", id: "" }
);

export const getToken = createSelector(storeToken, (token) => token);

export const isUserLoggedIn = createSelector(
  storeLoggedIn,
  (loggedIn) => loggedIn
);

export const getAccessType = createSelector(
  storeUserData,
  storeCurrentIsbData,
  (userData, currentIsbData) => {
    if (userData.isbData[currentIsbData.id]) {
      return userData.isbData[currentIsbData.id].accessType;
    }

    return "";
  }
);

export const isSuperAdmin = createSelector(storeUserData, (userData = {}) => {
  return userData.accessType === "super_admin";
});

export const getIsbVersionOptions = createSelector(
  storeUserData,
  (userData) => {
    const { isbData } = userData;
    if (!isbData || Object.keys(isbData).length === 0) {
      return [];
    }
    const options = [];

    Object.keys(isbData).forEach((key) => {
      const item = isbData[key];
      options.push({ name: item.version, value: key });
    });
    return options;
  }
);
