export const createRelationIterator = () => {
  return function* (sourceRelationObj) {
    const attr = this.attributes;
    let result = {};
    if (this.relationships) {
      result = sourceRelationObj.getRelationData(
        sourceRelationObj,
        this.relationships
      );
    }

    yield {
      ...attr,
      ...result,
    };
  };
};

export const getRelationData = (relationships, relationMap) => {
  const relationObj = {};
  Object.keys(relationships).forEach((key) => {
    const relationAttr = relationships[key];
    const relationData = relationAttr.data;

    if (!relationData) {
      return;
    }

    if (Array.isArray(relationData)) {
      const result = [];
      relationData.forEach((item) => {
        const itemKey = `${item.id}_${item.type}`;
        result.push(...relationMap[itemKey]);
      });
      relationObj[key] = [...result];
    } else if (typeof relationData === "object") {
      const itemKey = `${relationData.id}_${relationData.type}`;
      relationObj[key] = { ...relationMap[itemKey] };
    }
  });

  return relationObj;
};
