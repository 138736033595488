export const refundsRoutes = {
  refundsManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      createRefundRegistration: {
        path: "/create_refund_registration",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create",
        drawerIcon: "fas fa-pencil-alt",
      },
      refundRegistration: {
        path: "/refund_registration",
        accessType: ["admin", "super_admin"],
        drawerTitle: "View",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};
