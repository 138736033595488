import requestData from "./index";

export const unregisteredParticipantsApi = (pageNumber) => {
  return requestData(
    `/get_unregistered_participants_with_pagination?page=${pageNumber}`,
    {
      method: "GET",
      apiVersion: 2,
    }
  );
};

export const unregisteredParticipantsCountApi = () => {
  return requestData(`/get_unregistered_participants_count`, {
    method: "GET",
    apiVersion: 2,
  });
};

export const unregisteredUserApi = (emailOrMobile) => {
  const body = {
    phone_or_email: emailOrMobile,
  };
  return requestData("/search_failed_participant", {
    method: "POST",
    body,
    isFormData: true,
    apiVersion: 2,
  });
};

export const todaysIsbRegistrationApi = () => {
  return requestData(`/get_todays_total_isb_registrations`, {
    method: "GET",
    apiVersion: 2,
  });
};

export const downloadUnregisteredCsvApi = (fr, to) => {
  return requestData(
    `/csv_download_unregistered_participants.csv?from_id=${fr}&to_id=${to}`,
    {
      method: "GET",
      isBlob: true,
      apiVersion: 2,
    }
  );
};
