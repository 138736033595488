import { getSearchParams } from "../helpers";
import { createRelationIterator } from "../helpers/middleware";

const getPaginationData = (links, currentDataSize) => {
  const { self, prev, next, last } = links;
  const currentPage = Number(getSearchParams(self, "page[number]"));
  const totalPages = getSearchParams(last, "page[number]");

  return {
    hasNextPage: next ? true : false,
    hasPrevPage: prev ? true : false,
    prevPageNumber: prev ? currentPage - 1 : -1,
    currentPage: currentPage,
    nextPageNumber: next ? currentPage + 1 : -1,
    totalPages: totalPages ? Number(totalPages) : 0,
    currentPageSize: currentDataSize,
  };
};

const setupRelationsObject = (source = [], target) => {
  source.forEach((item) => {
    target[`${item.id}_${item.type}`] = {
      ...item,

      /* The iterator function need to be inserted when adding new data to the
          relation object in every relation item added
      */
      [Symbol.iterator]: createRelationIterator(),
    };
  });

  Object.assign(target, {
    getRelationData: (originalSource, relationships) => {
      const relationObj = {};
      Object.keys(relationships).forEach((key) => {
        const relationAttr = relationships[key];
        if (!relationAttr) {
          return;
        }

        const relationData = relationAttr.data;

        if (!relationData) {
          return;
        }

        if (Array.isArray(relationData)) {
          const result = [];
          relationData.forEach((item) => {
            const itemKey = `${item.id}_${item.type}`;
            if (originalSource[itemKey]) {
              const iterator = originalSource[itemKey][Symbol.iterator](
                originalSource
              );
              result.push(...iterator);
            } else {
              result.push("Data Missing");
            }
          });
          relationObj[key] = [...result];
        }

        if (typeof relationData === "object") {
          const itemKey = `${relationData.id}_${relationData.type}`;
          if (originalSource[itemKey]) {
            const iterator = originalSource[itemKey][Symbol.iterator](
              originalSource
            );
            relationObj[key] = [...iterator][0];
          } else {
            relationObj[key] = "Data Missing";
          }
        }
      });

      return relationObj;
    },
  });
};

const addItemToDataObject = (target, item) => {
  target[item.id] = {
    ...item,
  };
};

const addIteratorToDataObj = (target) => {
  Object.assign(target, {
    [Symbol.iterator]: function* () {
      const keys = Object.keys(this);

      for (let key of keys) {
        const item = this[key];
        if (typeof item !== "object") {
          continue;
        }

        yield {
          ...item.attributes,
          id: item.id,
          relationships: item.relationships,
        };
      }
    },

    getRelationData: (relationships, relationMap) => {
      const relationObj = {};
      Object.keys(relationships).forEach((key) => {
        const relationAttr = relationships[key];
        const relationData = relationAttr.data;

        if (!relationData) {
          return;
        }

        if (Array.isArray(relationData)) {
          const result = [];
          relationData.forEach((item) => {
            const itemKey = `${item.id}_${item.type}`;

            //  As per discussion there is a chance when relationships are there with id but no data in included
            if (relationMap[itemKey]) {
              const iterator = relationMap[itemKey][Symbol.iterator](
                relationMap
              );
              result.push(...iterator);
            } else {
              result.push("Data Missing");
            }
          });
          relationObj[key] = [...result];
        } else if (typeof relationData === "object") {
          const itemKey = `${relationData.id}_${relationData.type}`;

          //  As per discussion there is a chance when relationships are there with id but no data in included
          if (relationMap[itemKey]) {
            const iterator = relationMap[itemKey][Symbol.iterator](relationMap);
            relationObj[key] = [...iterator][0];
          } else {
            relationObj[key] = "Data Missing";
          }
        }
      });

      return relationObj;
    },
  });
};

const TableMiddleware = (store) => (next) => (action) => {
  const { payload, processTableData = true } = action;
  if (!payload || !processTableData) {
    next(action);
    return;
  }

  const { data, included = [], links = null } = payload;
  if (!data || !Array.isArray(data)) {
    next(action);
    return;
  }

  const dataObj = {};
  const relations = {};

  const dataIds = [];
  let paginationData = {};

  //  setting up the realtions according to ID
  setupRelationsObject(included, relations);

  //  setting up the data accoring to ID
  data.forEach((item) => {
    dataIds.push(item.id);
    addItemToDataObject(dataObj, item);
  });

  addIteratorToDataObj(dataObj);

  if (links) {
    paginationData = getPaginationData(links, Object.keys(dataObj).length);
  }

  const returnData = {
    ...action,
    payload: {
      dataIds,
      data: dataObj,
      relation: relations,
      paginationData,
    },
  };

  next(returnData);
};

export default TableMiddleware;
