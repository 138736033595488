import { GET_FAILED_REGISTRATIONS } from "../Actions";

const initialState = {
  totalRegistrations: null,
  data: {},
  relation: {},
  dataIds: [],
  onceDataFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const failedRegistrations = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_FAILED_REGISTRATIONS}_SUCCESS`: {
      const { data, dataIds, relation, paginationData } = payload;
      return {
        ...store,
        data,
        dataIds,
        relation,
        paginationData,
        onceDataFetched: true,
      };
    }

    default:
      return store;
  }
};

export default failedRegistrations;
