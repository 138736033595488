import React from "react";
import PropTypes from "prop-types";
import styles from "./ActivityIndicator.module.css";

const ActivityIndicator = ({ size, color }) => {
  const sizeClass = () => {
    switch (size) {
      case "s":
        return styles.size_s;
      case "m":
        return styles.size_m;
      case "l":
        return styles.size_l;
      default:
        return "";
    }
  };
  return (
    <div
      style={{
        borderColor: color
      }}
      className={`${styles.container} ${sizeClass()}`}
    />
  );
};

ActivityIndicator.propTypes = {
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
  color: PropTypes.string.isRequired,
};

export default ActivityIndicator;
